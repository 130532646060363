import { motion } from 'framer-motion';

import { TextWin } from 'js/components';

export default function Page() {
  // const navigate = useNavigate();

  // const [myTimeout, setMyTimeout] = useState(null);

  // const { gameId } = useParams();

  // useEffect(() => {
  //   // reset after a timeout
  //   setMyTimeout(
  //     setTimeout(function () {
  //       navigate('/final');
  //     }, resultTimeout)
  //   );

  //   // clear timeout if navigating away
  //   return () => (myTimeout ? clearTimeout(myTimeout) : null);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <main>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
      >
        <TextWin />
      </motion.div>
    </main>
  );
}
