// import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'js/components';

import style from './Idle.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import store from 'js/store/store';
import { useEffect } from 'react';

export const Idle = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.appModel.setState({ spinning: false });
  }, []);

  const content = useSelector((models) => models.appModel.content.pages.idle);
  const options = useSelector((models) => models.appModel.content.options);

  const startGame = () => {
    navigate('/' + gameId + '/play');
  };

  return (
    <div data-theme={gameId} className={style.wrap}>
      <div className={style.brandWrap}>
        <div className={style.brand} style={{ backgroundImage: 'url(' + options.logo + ')' }}></div>
      </div>
      <div className={style.title}>{content.title}</div>
      <div className={style.prefix}>{content.subtitle}</div>
      <Button onClick={startGame}>Start</Button>
    </div>
  );
};

export default Idle;
