// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
// import { AnimatePresence, motion } from 'framer-motion';

import style from './TextWin.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../Button/Button';
import { useEffect } from 'react';

export const TextWin = () => {
  const content = useSelector((models) => models.appModel.content.pages.win);
  const options = useSelector((models) => models.appModel.content.options);

  // const prizeCode = useSelector((models) => models.appModel.prizeCode);
  const prizeName = useSelector((models) => models.appModel.prizeName);
  const { gameId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.appModel.setState({ spinning: false });
  }, []);

  const playAgain = () => {
    navigate('/' + gameId);
  };

  return (
    <div data-theme={gameId} className={style.wrap}>
      <div className={style.title}>{content.title}</div>
      <div className={style.prize}>{prizeName}</div>
      <div className={style.collect}>{content.panel_body}</div>
      <img className={style.qr} alt="qr" src={options.qr} />
      <Button onClick={playAgain}>Spin Again</Button>
    </div>
  );
};

export default TextWin;
